@charset "UTF-8";

/* Modify the down arrows to have a blue color */
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    border-color: rgb(84, 151, 229);
}

.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: none; /* Disable borders */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
}

/* Modify the navigation arrows to be blue */
.react-datepicker__navigation-icon::before {
    border-color: rgb(84, 151, 229); /* Blue color for the arrows */
}

/* Modify the header background to be white */
.react-datepicker__header {
    background-color: #fff; /* White background */
    border-bottom: none; /* No bottom border */
}

/* Modify the selected day background to be blue and text to be white */
.react-datepicker__day--selected {
    background-color: rgb(84, 151, 229); /* Blue background for selected day */
    color: #ffffff; /* White text for selected day */
}

/* Modify the hover state for days to have a blue background */
.react-datepicker__day:hover {
    background-color: rgba(84, 151, 229, 0.3); /* Light blue background on hover */
}

/* Modify the current day color to be black */
.react-datepicker__day--today {
    color: black; /* Black text for the current day */
}

/* Ensure the triangle is removed or styled appropriately */
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    display: none; /* Remove the triangle if not needed */
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: rgb(84, 151, 229);
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    font-weight: normal; /* Non-bold text */
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

/* Apply styles directly to the input or button that triggers the datepicker */
.react-datepicker__input-container input,
.button-triggering-datepicker {
    border: 1px solid rgb(195, 229, 254);
    border-radius: 45px; /* Slightly rounded corners for the input field */
    box-shadow: none; /* No shadow for flat design */
}

.react-datepicker__input-container input:focus {
    border-color: rgb(56, 153, 237); /* Maintain blue border color */
    box-shadow: 0 0 0 3px rgb(170, 219, 252); /* Increase the shadow size */
}

:focus-visible {
    outline: none;
}

/* Apply hover styles directly to the input that triggers the datepicker */
.react-datepicker__input-container input:hover,
.button-triggering-datepicker:hover {
    background-color: rgb(236, 247, 254); /* Light blue background on hover */
}

.react-datepicker-wrapper .react-datepicker__view-calendar-icon input[type=text]{
    width: 165px;
    height: 24px;
}

.react-datepicker-ignore-onclickoutside {
    border-top-width: 0;
    border-left-width: 0;
    padding-top: 6px;
    padding-bottom: 5px;
    border-right-width: 0;
    border-bottom-width: 0;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
    padding-top: 8px;
    padding-bottom: 9px;
}

.react-datepicker__calendar-icon {
    width: 1.2em;
    height: 1.2em;
}
.react-datepicker__view-calendar-icon input {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
}
